<template>
  <v-col cols="12" md="4">
    <v-row>
      <v-col cols="12">
        <v-select
          :items="dutConfigsList"
          label="DUT Configs"
          item-text="name"
          item-value="pk"
          v-model="selectedDut"
          hide-details
          :disabled="!isInitialState"
          @change="refreshFormData"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.pk }} - {{ data.item.name }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <DutConfigForm
        ref="dutform"
        :dutConfig="dutConfigs"
        :defaultDut="defaultDut"
        :renderSet="renderSet"
        :isInitialState="isInitialState"
        :isAdmin="isAdmin"
        @saveData="saveForm"
        @generate="onGenerate"
        @configure="onConfigure"
      />
    </v-row>
  </v-col>
</template>
<script>
export default {
  props: {
    dutConfigsList: {
      type: Array,
      default: null,
    },
    defaultDut: {
      type: Number,
      default: null,
    },
    renderSet: {
      type: Number,
      default: null,
    },
    isInitialState: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedDut: this.defaultDut,
    };
  },
  components: {
    DutConfigForm: () => import("./DutConfigForm.vue"),
  },
  computed: {
    dutConfigs() {
      const record = this.dutConfigsList.find(
        (element) => element.pk == this.selectedDut
      );
      return record ;
    },
  },
  methods: {
    onGenerate() {
      this.$emit("generate");
    },
    onConfigure() {
      this.$emit("configure");
    },
    saveForm(payload) {
      this.$emit("update", payload);
    },
    refreshFormData() {
      this.$refs.dutform.refreshData();
      this.$emit("updateDutId", this.selectedDut);
    }
  },
};
</script>
